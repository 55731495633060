import * as yup from 'yup';

export const measuresSchema = yup.object({
  cycle: yup
    .number()
    .typeError('common.form.validation.shouldBeNumber')
    .required('common.form.validation.required'),
  measureTypeId: yup.number().required('common.form.validation.required'),
  measures: yup.array().of(
    yup.object({
      value: yup
        .number()
        .nullable()
        .typeError('common.form.validation.shouldBeNumber'),
      id: yup.number().nullable(),
    }),
  ),
});
